<template>
  <header class="header">
    <top-bar />
    <div class="custom-container menu_main" :class="{ scrolled: isScroll }">
      <!-- <div class="header-logo lg2">
        <div>
          <router-link :to="{ name: 'home', params: { lang: $route.params.lang } }"
            ><img alt="logo" :src="`${require('@/images/logo.png')}`"
          /></router-link>
        </div>
      </div>
      <nav class="header-navigation lg10"><navigation /></nav> -->
      <div class="position-absolute header_logo">
        <div class="navbar">
          <h1>
            <router-link :to="`/${this.$route.params.lang}/`"
              ><b-img-lazy alt="logo" :src="`${require('@/images/logo.png')}`"
            /></router-link>
          </h1>
        </div>
      </div>
      <div>
        <Navigation :key="$route.path" />
      </div>
    </div>
  </header>
</template>

<script>
import TopBar from './TopBar'
import Navigation from '@/components/navigation'
import $ from 'jquery'
export default {
  components: {
    TopBar,
    Navigation
  },
  data() {
    return {
      isScroll: false
    }
  },
  mounted() {
    var that = this

    function getBodyScrollTop() {
      const el = document.scrollingElement || document.documentElement
      return el.scrollTop
    }
    window.addEventListener('scroll', function() {
      var scrollTop = getBodyScrollTop()
      if (scrollTop > 0) {
        if (window.innerWidth > 991) {
          that.isScroll = true
          $('.app-wrapper:not(.lang-en) .navbar-nav .dropdown-menu:not(.small)').css({
            top: 133 + 'px'
          })
          $('.app-wrapper:not(.lang-en) .main-container').css({
            marginTop:
              ($('.nav_pc').css('display') == 'block'
                ? $('.main-container .notice').outerHeight() || 0
                : ($('.main-container .notice').outerHeight() || 0) + 36) +
              133 +
              'px'
          })
        } else {
          $('.app-wrapper:not(.lang-en) .main-container').css({
            marginTop: 72 + 'px'
          })
        }
      } else {
        that.isScroll = false
        $('.navbar-nav .dropdown-menu:not(.small)').css({
          top: 136 + 'px'
        })
        $('.main-container').css({
          marginTop:
            ($('.nav_pc').css('display') == 'block'
              ? $('.main-container .notice').outerHeight() || 0
              : ($('.main-container .notice').outerHeight() || 0) + 20) +
            149 +
            'px'
        })
      }
    })
    this.takeNoticeHeight()
  },
  methods: {
    changeLocale(locale) {
      this.$switchLanguage(locale)
    },
    takeNoticeHeight() {
      $('.navbar-nav .dropdown-menu:not(.small)').css({
        // top: ($('.main-container .notice').outerHeight() || 0) + 136 + 'px'
        // top: ($('.main-container .header .menu_main').outerHeight() || 0) + 34 + 'px'
        top: 136 + 'px'
      })
      $('.menu .nav_mb .mb_dropdown').css({
        // top: ($('.main-container .notice').outerHeight() || 0) + 168 + 'px'
        top: 168 + 'px'
      })
      $('.header').css({
        top: ($('.main-container .notice').outerHeight() || 0) + 0 + 'px'
      })
      $('.main-container').css({
        // paddingTop: ($('.main-container .notice').outerHeight() || 0) + 149 + 'px'
        marginTop:
          ($('.nav_pc').css('display') == 'block'
            ? $('.navbar-nav .dropdown-menu:not(.small)').css('top') == '120px'
              ? ($('.main-container .notice').outerHeight() || 0) - 16
              : $('.main-container .notice').outerHeight() || 0
            : ($('.main-container .notice').outerHeight() || 0) + 20) +
          149 +
          'px'
      })
    }
  }
}
</script>
