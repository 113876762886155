<template>
  <div class="header_top">
    <div class="custom-container header_top_pc">
      <b-row class="contain" style="justify-content: space-between; margin-left: 0px; margin-right: 0px">
        <div class="left" v-if="top_phone">
          <b-img-lazy
            class="iconImg iconPhone"
            :src="`${require('../../assets/images/top_icon_phone.png')}`"
            :alt="$t('總機')"
          />
          <!-- <span>{{ $t('總機') }}:（853）2832 2323</span> -->
          <span v-html="top_phone[lang]"></span>
        </div>
        <div class="right">
          <div class="content">
            <div class="d-inline-block pic">
              <div class="stock left_chart">
                <div class="stock_png1" :title="$t('恆生指數')"></div>
                <div class="stock_title border_right">
                  {{ $t('恆生指數') }}
                </div>
                <div class="chart">
                  <b-img-lazy
                    src="//content.etnet.com.hk/content/taifung/common/chart_oneminute.php?code=HSI"
                    :alt="$t('恆生指數')"
                  />
                  <span>{{ $t('各大指數延遲最少15分鐘') }}</span>
                </div>
              </div>
              <div class="stock right_chart">
                <div class="stock_png2" :title="$t('上證綜指')"></div>
                <div class="stock_title">
                  {{ $t('上證綜指') }}
                </div>
                <div class="chart">
                  <b-img-lazy
                    src="//content.etnet.com.hk/content/taifung/common/chart_oneminute.php?type=ashare&code=CSI.000001"
                    :alt="$t('上證綜指')"
                  />
                  <span>{{ $t('各大指數延遲最少15分鐘') }}</span>
                </div>
              </div>
            </div>
            <b-form inline class="form-inline position-relative" @submit.stop.prevent>
              <i class="search" @click="handleSearch" title="search"></i>
              <b-form-input type="text" v-model="searchText" @keyup.enter="handleSearch" />
            </b-form>
            <b-link rel="" :to="{ name: 'search' }" class="form_logo">
              <b-img-lazy
                :src="`${require('@/assets/images/top_icon_search.png')}`"
                style="background: #e9eaeb;padding:.4rem;"
                alt="search"
              />
            </b-link>
            <a rel="noopener noreferrer" href="#" @click="print()"><i class="print" title="print"></i></a>
            <a rel="noopener noreferrer" href="//www.facebook.com/taifungbankmo" target="_blank"
              ><i class="facebook" title="facebook"></i
            ></a>
            <a rel="" href="#">
              <i class="top_wechat" :title="$t('關注微訊信號')">
                <div class="top_icon_qr">
                  <b-img-lazy :src="`${require('../../assets/images/icon_qr.jpg')}`" :alt="$t('關注微訊信號')" />
                  <p>{{ $t('關注微訊信號') }}</p>
                </div>
              </i>
            </a>
            <!-- <a rel="" href="#"><i class="ins"></i></a> -->
            <div class="font_size">
              <div style="padding-top: 5px;">
                <span class="sm-size" @click="changefontSize(12)">A-</span>
                <span class="md-size" @click="changefontSize(16)">A</span>
                <span class="lg-size" @click="changefontSize(20)">A+</span>
              </div>
            </div>
            <!-- <b-nav-item-dropdown :text="$t('語言')" variant="info" right class="language">
              <b-dropdown-item @click="changeLocale('zh-hant')">繁</b-dropdown-item>
              <b-dropdown-item @click="changeLocale('zh-hans')">簡</b-dropdown-item>
              <b-dropdown-item @click="changeLocale('en')">ENG</b-dropdown-item>
            </b-nav-item-dropdown> -->
            <div class="language">
              <b-link @click="changeLocale('zh-hant')" class="mr-2">繁</b-link>
              <b-link @click="changeLocale('zh-hans')" class="mr-2">简</b-link>
              <b-link @click="changeLocale('en')">ENG</b-link>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <div class="header_top_mobile">
      <div>
        <b-row class="row_top">
          <b-col cols="2" class="br">
            <b-link
              rel="noopener noreferrer"
              v-if="isMounted && top_phone"
              :href="`tel:${top_phone[lang].split(':')[1]}`"
            >
              <b-img-lazy
                class="iconImg iconPhone"
                :src="`${require('../../assets/images/top_icon_phone.png')}`"
                :alt="$t('總機')"
              />
            </b-link>
          </b-col>
          <b-col cols="5" class="br stock left_chart" @click="isShowLeftChart = true">
            <!-- <b-img class="icon_stock" :src="`${require('@/assets/images/top_icon_stock1.png')}`"/> -->
            <a
              :href="homepage.navigation[0].url"
              :target="homepage.navigation[0].newWindow ? '_blank' : '_self'"
              v-if="homepage"
              class=""
            >
              <!--<div class="stock_png1" :title="$t('個人網銀')"></div>-->
              <div class="stock_title">{{ $t('個人網銀') }}</div>
            </a>
          </b-col>
          <b-col cols="5" class="stock right_chart" @click="isShowRightChart = true">
            <!-- <b-img class="icon_stock" :src="`${require('@/assets/images/top_icon_stock2.png')}`"/> -->
            <a
              :href="homepage.navigation[1].url"
              :target="homepage.navigation[1].newWindow ? '_blank' : '_self'"
              v-if="homepage"
            >
              <!--<div class="stock_png2" :title="$t('企業網銀')"></div>-->
              <div class="stock_title">{{ $t('企業網銀') }}</div>
            </a>
          </b-col>
        </b-row>
        <b-row class="row_bottom">
          <b-col class="p-0 mobile_icon" cols="auto">
            <b-link rel="" :to="{ name: 'search' }">
              <b-img-lazy
                class="iconImg"
                :src="`${require('@/assets/images/top_icon_search.png')}`"
                style="background: #e9eaeb; padding: .4rem"
                alt="search"
              />
            </b-link>
            <a rel="" href="#" @click="print()">
              <b-img-lazy class="iconImg" :src="`${require('@/assets/images/top_icon_print.png')}`" alt="print" />
            </a>
            <a rel="noopener noreferrer" href="//www.facebook.com/taifungbankmo" target="_blank">
              <b-img-lazy class="iconImg" :src="`${require('@/assets/images/top_icon_facebook.png')}`" alt="facebook" />
            </a>
            <a rel="" href="#" class="mob_wechat">
              <b-img-lazy
                class="iconImg"
                :src="`${require('@/assets/images/top_icon_wechat.png')}`"
                :alt="$t('關注微訊信號')"
              />
              <div class="top_icon_qr">
                <b-img-lazy :src="`${require('@/assets/images/icon_qr.jpg')}`" :alt="$t('關注微訊信號')" />
                <p>{{ $t('關注微訊信號') }}</p>
              </div>
            </a>
            <!-- <a rel="" href="#"><b-img class="iconImg" :src="`${require('@/images/top_icon_pin.png')}`"/></a> -->
          </b-col>
          <b-col class="p-0 d-flex col">
            <button class="font_size align-top col">
              <span class="sm-size" @click="changefontSize(12)">A-</span>
              <span class="md-size" @click="changefontSize(16)">A</span>
              <span class="lg-size" @click="changefontSize(20)">A+</span>
            </button>
            <!-- <div class="language d-inline-block col">
              <b-nav-item-dropdown :text="$t('語言')" variant="info" right class="px-0 text-center">
                <b-dropdown-item @click="changeLocale('zh-hant')">繁體</b-dropdown-item>
                <b-dropdown-item @click="changeLocale('zh-hans')">简体</b-dropdown-item>
                <b-dropdown-item @click="changeLocale('en')">English</b-dropdown-item>
              </b-nav-item-dropdown>
            </div> -->
            <div class="language d-flex col px-0 align-items-center justify-content-center">
              <div class="d-flex align-items-center px-0 text-center">
                <b-link @click="changeLocale('zh-hant')" class="mr-1">繁</b-link>
                <b-link @click="changeLocale('zh-hans')" class="mr-1">简</b-link>
                <b-link @click="changeLocale('en')">ENG</b-link>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { lanToISO639 } from '@/utils'
import $ from 'jquery'
export default {
  name: 'top-bar',
  data() {
    return {
      searchText: '',
      isShowLeftChart: false,
      isShowRightChart: false,
      isMounted: false
    }
  },
  computed: {
    ...mapState('page', ['homepage']),
    ...mapState('common', ['top_phone']),
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  mounted() {
    if (!this.homepage) {
      this.fetchindexInitData()
    }
    this.isMounted = true
  },
  methods: {
    fetchindexInitData() {
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('page/FETCH_HOMEPAGE', {
            cookies
          })
        ])
          .finally(() => {
            resolve()
          })
          .catch(reject)
      })
    },
    changeLocale(locale) {
      this.$switchLanguage(locale)
    },
    handleSearch(e) {
      if (this.searchText) {
        this.$router.push({
          name: 'search',
          query: {
            text: this.searchText.replace(/\s+/g, '')
          }
        })
        return false
      }
    },
    print() {
      window.print()
    },
    // changefontSize() {
    //   var font = localStorage.getItem('font_reset_sizie')
    //   var direction = localStorage.getItem('font_reset_direction')
    //   if (!font) font = 16
    //   if (direction == null) {
    //     direction = 'in'
    //   }
    //   $('body').removeClass('font-' + font)
    //   if (font == 18 || font == 15) {
    //     if (direction === 'in') {
    //       direction = 'sub'
    //     } else {
    //       direction = 'in'
    //     }
    //   }
    //   if (direction === 'in') {
    //     font++
    //     $('body').addClass('font-' + font)
    //   } else {
    //     font--
    //     $('body').addClass('font-' + font)
    //   }
    //   // $("html").css('font-size', font + "px");
    //   localStorage.setItem('font_reset_sizie', font)
    //   localStorage.setItem('font_reset_direction', direction)
    //   localStorage.setItem('font_reset_date', new Date())
    // },
    changefontSize(newValue) {
      var font = localStorage.getItem('font_reset_sizie')
      if (!font) font = 16
      $('body').removeClass('font-' + font)
      $('body').addClass('font-' + newValue)
      localStorage.setItem('font_reset_sizie', newValue)
      localStorage.setItem('font_reset_date', new Date())

      $('.navbar-nav .dropdown-menu:not(.small)').css({
        // top: ($('.main-container .notice').outerHeight() || 0) + 136 + 'px'
        top: ($('.main-container .header .menu_main').outerHeight() || 0) + 34 + 'px'
        // top: 136 + 'px'
      })
      $('.menu .nav_mb .mb_dropdown').css({
        // top: ($('.main-container .notice').outerHeight() || 0) + 168 + 'px'
        top: 168 + 'px'
      })
      $('.header').css({
        top: ($('.main-container .notice').outerHeight() || 0) + 0 + 'px'
      })
      $('.main-container').css({
        // paddingTop: ($('.main-container .notice').outerHeight() || 0) + 149 + 'px'
        marginTop:
          ($('.nav_pc').css('display') == 'block'
            ? $('.navbar-nav .dropdown-menu:not(.small)').css('top') == '120px'
              ? ($('.main-container .notice').outerHeight() || 0) - 16
              : $('.main-container .notice').outerHeight() || 0
            : ($('.main-container .notice').outerHeight() || 0) + 20) +
          149 +
          'px'
      })
    }
  }
}
</script>
