import request, { getCommonHeader } from '@/utils/request'

export function FetchAreas({ cookies }) {
  return request.get('/api/v1/bank/area', {
    headers: getCommonHeader({ cookies })
  })
}

export function FetchTypes({ cookies }) {
  return request.get('/api/v1/bank/type', {
    headers: getCommonHeader({ cookies })
  })
}

export function FetchBanks({ cookies, preview, id, vid }) {
  return request.get('/api/v1/bank/get_banks', {
    headers: getCommonHeader({ cookies }),
    params: { preview, id, vid }
  })
}
