<template>
  <div :class="['app-wrapper', `page-${name}`, `lang-${locale}`]">
    <Notice />
    <PopUp />
    <app-main />
  </div>
</template>

<script>
import AppMain from './components/AppMain'
import Notice from './components/Notice'
import PopUp from './components/PopUp'

export default {
  name: 'Layout',
  components: {
    // AppMain
    Notice,
    PopUp
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    name() {
      return this.$route.name
    }
  }
}
</script>
