<template>
  <div>
    <b-container v-if="!preview">
      <b-breadcrumb separator-class="el-icon-arrow-right">
        <!--{{ $t('你在此：') }}-->
        <b-breadcrumb-item :to="`/${lang}/`">{{ $t('主頁') }}</b-breadcrumb-item>
        <template v-if="breadList.length > 0">
          <b-breadcrumb-item
            v-for="item in breadList"
            :key="item.name"
            :to="`/${lang}/${item.path}`"
            :active="!item.path"
            >{{ item.title }}</b-breadcrumb-item
          >
        </template>
        <template v-else>
          <b-breadcrumb-item :active="false">{{ breadTitle }}</b-breadcrumb-item>
        </template>
      </b-breadcrumb>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ISO639ToLan } from '../../utils'
import { formData } from '@/api/dynamic_form'
export default {
  data() {
    return {
      breadList: [],
      breadTitle: '',
      preview: false
    }
  },
  watch: {
    page() {
      this.$route.name == 'form-type' || this.$route.name == 'activity-detail'
        ? this.getBreadcrumbSpecial(this.$route.name)
        : this.getBreadcrumb()
    },
    activity() {
      this.getBreadcrumbSpecial('activity-detail')
    }
  },
  serverPrefetch() {},
  mounted() {
    if (this.$route.query.preview) {
      this.preview = true
    } else {
      this.preview = false
      this.$route.name == 'form-type' || this.$route.name == 'activity-detail'
        ? this.getBreadcrumbSpecial(this.$route.name)
        : this.getBreadcrumb()
    }
  },
  computed: {
    ...mapState('common', ['menu', 'page']),
    ...mapState('page', ['activity']),
    lang() {
      return ISO639ToLan(this.$getLocale())
    }
  },
  methods: {
    async getBreadcrumb() {
      if (await this.page) {
        this.breadTitle = this.page.title
        const menus = this.menu

        var breadList = []
        var result = null
        if (menus && this.page) {
          menus.some(element => {
            breadList = []
            result = this.searchTree(element, breadList)
            // if (result != null) {
            this.breadList = breadList
            // }
            return result
          })
        }
      }
    },
    getBreadcrumbSpecial(name) {
      if (name == 'form-type') {
        let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
        let slug = path.replace(`dynamic-form/`, '')
        const menus = this.menu
        formData({ slug }).then(res => {
          this.breadTitle = res.data.title
          this.breadList = []
        })
      }
      if (name == 'activity-detail') {
        this.breadTitle = this.activity.title
        this.breadList = []
      }
    },

    searchTree(element, breadList) {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      if (element.path == decodeURI(path)) {
        breadList.push(element)
        return element
      } else if (element.chilrends != null) {
        var i
        var result = null
        breadList.push(element)

        for (i = 0; result == null && i < element.chilrends.length; i++) {
          result = this.searchTree(element.chilrends[i], breadList)
        }
        if (result == null) {
          breadList.pop()
        }

        return result
      }
      return null
    }
  }
}
</script>
