<template>
<div class="main-container">
  <Header />
  <template v-if="preview">
    <Breadcrumb v-if="name == 'index' ? false : true" />
  </template>
  <div class="main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </div>
  <Footer />
</div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import Breadcrumb from './Breadcrumb'
export default {
  name: 'layout-main',
  data() {
    return {
      preview: false
    }
  },
  components: {
    Header,
    Footer,
    Breadcrumb
  },
  computed: {
    key() {
      return this.$route.path
    },
    name() {
      return this.$route.name
    }
  }
}
</script>
