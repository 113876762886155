import {
  fetchMenu,
  fetchFooter,
  fetchPage,
  fetchNotice,
  fetchSettings,
  fetchLayout,
  fetchSelection,
  fetchHeadEndCode,
  fetchLink,
  fetchSystemSettings
} from '@/api/common'

const state = {
  serverFetched: false,
  menu: null,
  footers: {
    menu1: [],
    menu2: []
  },
  page: null,
  notice: null,
  popUp: null,
  setting: null,
  template: null,
  home_activity: null,
  top_phone: null,
  selection: null,
  cdn_link: null,
  grayStyle: null
}
const mutations = {
  SET_LAYOUT: (state, { data }) => {
    state.menu = data.menu
    state.footers.menu1 = data.footMenuOne
    state.footers.menu2 = data.footMenuTwo
  },
  SET_MENU: (state, { menu }) => {
    state.menu = menu
  },
  SET_FOOTER: (state, { mark, data }) => {
    state.footers[mark] = data
  },
  SET_PAGE: (state, data) => {
    state.page = data
  },
  SET_NOTICE: (state, data) => {
    state.notice = data
  },
  SET_POPUP: (state, data) => {
    state.popUp = data
  },
  SET_SERVER_FETCHED: (state, isFetched) => {
    state.serverFetched = isFetched
  },
  SET_SETTING: (state, data) => {
    state.setting = data
  },
  SET_TEMPLATE: (state, data) => {
    state.template = data.data
  },
  SET_HOME_ACTIVITY: (state, data) => {
    state.home_activity = data.data
  },
  SET_TOP_PHONE: (state, data) => {
    state.top_phone = JSON.parse(data.data)
  },
  SET_SELECTION: (state, data) => {
    // state.selection = data.data
    let obj = {}
    obj[data.type] = data.data
    state.selection = Object.assign({}, state.selection, obj)
  },
  SET_LINK: (state, data) => {
    state.cdn_link = data.data
  },
  SET_GRAYSTYLE: (state, data) => {
    state.grayStyle = data.data
  }
}

const actions = {
  FETCH_LINK: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      fetchLink(params)
        .then(res => {
          commit('SET_LINK', { data: res.data })
          resolve()
        })
        .catch(() => {
          commit('SET_LINK', { data: '404' })
          resolve()
        })
    })
  },
  FETCH_LAYOUT: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      fetchLayout().then(res => {
        commit('SET_LAYOUT', { data: res.data })
        resolve()
      })
    })
  },
  FETCH_MENU: ({ commit, state }) => {
    return fetchMenu().then(res => {
      commit('SET_MENU', { menu: res.data })
    })
  },
  FETCH_FOOTER: ({ commit }, mark) => {
    return fetchFooter(`footer-${mark}`).then(res => {
      commit('SET_FOOTER', { mark, data: res.data })
    })
  },
  FETCH_PAGE: ({ commit }, { path, cookies, preview, id, vid }) => {
    return new Promise((resolve, reject) => {
      fetchPage({ path, cookies, preview, id, vid }).then(res => {
        commit('SET_PAGE', res.data)
        resolve()
      })
    })
  },
  FETCH_NOTICE: ({ commit }, { slug }) => {
    return new Promise((resolve, reject) => {
      fetchNotice({ slug }).then(res => {
        commit('SET_NOTICE', res.data)
        resolve()
      })
    })
    // return fetchNotice().then(res => {
    //   commit('SET_NOTICE', { data: res.data })
    // })
  },
  FETCH_POPUP: ({ commit }, { slug }) => {
    return new Promise((resolve, reject) => {
      fetchNotice({ slug }).then(res => {
        commit('SET_POPUP', res.data)
        resolve()
      })
    })
  },
  FETCH_SETTING: ({ commit }) => {
    return fetchSettings({ identify: 'config', ck: 'general-settings' }).then(res => {
      commit('SET_SETTING', { data: res.data })
    })
  },
  FETCH_TEMPLATE: ({ commit }) => {
    return fetchSettings({ identify: 'config', ck: 'template' }).then(res => {
      commit('SET_TEMPLATE', { data: res.data })
    })
  },
  FETCH_HOME_ACTIVITY: ({ commit }) => {
    return fetchSettings({ identify: 'config', ck: 'show_home_activity' }).then(res => {
      commit('SET_HOME_ACTIVITY', { data: res.data })
    })
  },
  FETCH_TOP_PHONE: ({ commit }) => {
    return fetchSettings({ identify: 'config', ck: 'top_phone' }).then(res => {
      commit('SET_TOP_PHONE', { data: res.data })
    })
  },
  FETCH_SELECTION: ({ commit }, params) => {
    return fetchSelection({ ...params }).then(res => {
      commit('SET_SELECTION', { data: res.data, type: params.type })
    })
  },
  FETCH_GRAYSTYLE: ({ commit }) => {
    return fetchSystemSettings({ identify: '_system', ck: 'enable_gray_style' }).then(res => {
      commit('SET_GRAYSTYLE', { data: res.data.parameter })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
