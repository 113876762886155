export function isSSR() {
  return process.env.VUE_ENV === 'server'
}

export function isCSR() {
  return process.env.VUE_ENV === 'client'
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]  
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @param {*} lang
 */
export function lanToISO639(lang) {
  switch (lang) {
    case 'zh-hant':
      return 'zh_TW'
    case 'zh-hans':
      return 'zh_CN'
    case 'en':
      return 'en'
    default:
      return lang
  }
}

/**
 * @param {*} lang
 */
export function ISO639ToLan(lang) {
  switch (lang) {
    case 'zh_TW':
      return 'zh-hant'
    case 'zh_CN':
      return 'zh-hans'
    case 'en':
      return 'en'
    default:
      return lang
  }
}

/**
 * @param {*} lang
 */
export function lanToOtherISO639(lang) {
  switch (lang) {
    case 'zh-hant':
      return 'zh-tw'
    case 'zh-hans':
      return 'zh-cn'
    case 'en':
      return 'en'
    default:
      return lang
  }
}

/**
 * 去掉两端空格
 * @param {*} str
 */
export function trimSpace(str) {
  if (String.prototype.trim) return str.trim()
  return str.replace(/^\s+(.*?)\s+$/g, '$1')
}

/**
 * 生成hash
 * @param {*} hashLength 默认长度
 */
export function createHash(hashLength) {
  return Array.from(Array(Number(hashLength) || 24), () => Math.floor(Math.random() * 36).toString(36)).join('')
}

export function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString})
}
