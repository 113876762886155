export function localeTxt (content, lang) {
    if(content){
        return content[lang]
    }
}

export function formatMoney (value) {
    if (value) {
      value = Number(value);
      return '￥ ' + value.toFixed(2);
    }
}