import Vue from 'vue'
import { createApp } from './main'
import ProgressBar from './components/progress-bar'

const bar = (Vue.prototype.$bar = new Vue(ProgressBar).$mount())
document.body.appendChild(bar.$el)

const { app, router, store } = createApp()

// prime the store with server-initialized state.
// the state is determined during SSR and inlined in the page markup.
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
  router.afterEach((to, from) => {
    // 由服务端渲染转入客户端渲染
    store.commit('common/SET_SERVER_FETCHED', false)
  })
  app.$mount('#app')
})
