export default {
    uploadRequired: {
        getMessage: field => 'uploadRequired',
        validate: value => value.length !== 0
    },
    chinese: {
        getMessage: field => '請輸入中文',
        validate: value => /.*[\u4e00-\u9fa5]+.*$/.test(value)
    },
    englist: {
        getMessage: field => '請輸入英文',
        validate: value => /[a-zA-Z]/.test(value)
    },
    position: {
        getMessage: field => '請輸入數字',
        validate: value => /^[0-9]*$/.test(value)
    },
    phone: {
        getMessage: field => 'phone',
        validate: value => value.length === 8 && /^6\d{7}$/.test(value)
    },
    link: {
        getMessage: field => 'link',
        validate: value => /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(value)
    },
    passverification: {
        getMessage: field => 'passverification',
        // validate: value => /^(?![A-Za-z0-9]+$)(?![A-Za-z\W]+$)(?![0-9\W]+$)[0-9a-zA-Z~`!@#$%*()_\-+|&}{:;?.\/\\<>’”]{8,20}$/.test(value)
        validate: value => /^[0-9a-zA-Z~`!@#$%*()_\-+|&}{:;?.\/\\<>’”]{8,20}$/.test(value)
    },
    timeEvent: {
        getMessage: field => 'timeEvent',
        validate: (value, args) => {
            //   console.log(value, args[0])
            return false
        }
    },
    noTrim:{
        getMessage: field => '不能有空格',
        validate: value => /^[^\s]*$/.test(value)
    },
    idNumber:{
        getMessage: field => i18n.$t('身份證號碼格式不正確'),
        validate: value => value.length == 8 && /^(1|5|7)\d{7}$/.test(value),
    }
}
