import request, { getCommonHeader } from '@/utils/request'

/**
 * 获取職位
 * @param {*} params
 */
export function FetchApplyJobs({ cookies, params }) {
    return request.get('/api/v1/join_us/apply_jobs', {
        headers: getCommonHeader({ cookies }),
        params
    })
}

/**
 * 表单提交
 * @param {*} params 
 * @param {*} data 
 */
export function TakeSubmit(cookies, data) {
    return request.post('/api/v1/join_us/submit', {
        ...data
    })
}

/**
 * 上傳
 * @param {*} params 
 * @param {*} data 
 */
export function TakeUpload(file = {}) {
    return request.post('/api/v1.0/common/upload', {
        file
    })
}

/**
 * 區號
 * @param {*} params 
 * @param {*} data 
 */
export function FetchRegionList(cookies, params) {
    return request.get('/api/v1/join_us/region_list', {
        headers: getCommonHeader({ cookies }),
        params
    })
}

/**
 * 国籍
 * @param {*} params 
 * @param {*} data 
 */
export function FetchNationList(cookies, params) {
    return request('/api/v1/join_us/nation_list', {
        headers: getCommonHeader({ cookies }),
        params
    })
}
