<template>
  <footer class="text-white docs-border">
    <div class="container h-100 clearfix d-flex flex-wrap-reverse p-0 px-md-3">
      <section class="col-md-8 col-sm-12 col-12">
        <div class="footer_top">
          <ul>
            <li v-for="footerTop in footers.menu1" :key="footerTop.id" v-show="footerTop.title">
              <router-link :title="footerTop.title" :to="footerTop | url(lang)">{{ footerTop.title }}</router-link>
            </li>
          </ul>
        </div>

        <div class="clearfix item_list">
          <ul class="col-5 col-sm-6 col-md-3" v-for="number in Math.ceil(footers.menu2.length / 4)" :key="number">
            <li v-for="footerBottom in footers_arr[number - 1]" :key="footerBottom.id" v-show="footerBottom.title">
              <!-- <a rel="" :href="footerBottom.url" :target="footerBottom.newWindow?'_blank':''">{{footerBottom.title}}</a> -->
              <b-link
                v-if="footerBottom.url"
                :href="footerBottom.url"
                :title="footerBottom.title"
                :target="footerBottom.newWindow ? '_blank' : '_self'"
                >{{ footerBottom.title }}</b-link
              >
              <b-link
                v-else
                :to="footerBottom | url(lang)"
                :title="footerBottom.title"
                :target="footerBottom.newWindow ? '_blank' : '_self'"
                >{{ footerBottom.title }}</b-link
              >
            </li>
          </ul>
        </div>
      </section>
      <div class="footer_right col-md-4 col-12">
        <div class="clearfix d-flex flex-wrap">
          <b-img-lazy :src="`${require('@/assets/images/app_logo.png')}`" class="float-left" :alt="$t('豐付寶')" />
          <div class="logo_text float-right mx-2">
            <p class="title">{{ $t('豐付寶') }}</p>
            <p>
              {{ $t('大豐銀行全新移動銀行應用程式「豐付寶」，全新界面，全新風格，全新體驗！') }}
            </p>
            <div class="qrcode text-left my-3 d-flex">
              <div>
                <a
                  rel="noopener noreferrer"
                  class="d-block"
                  href="//apps.apple.com/cn/app/da-feng-yin-xing/id500132293"
                  target="_blank"
                >
                  <b-img-lazy :src="`${require('@/assets/images/app_store.png')}`" alt="App Store" />
                </a>
                <!-- <p class="text-center img-dec">App Store</p> -->
                <a
                  rel="noopener noreferrer"
                  class="d-block"
                  href="//apps.apple.com/cn/app/da-feng-yin-xing/id500132293"
                  target="_blank"
                >
                  <b-img-lazy
                    :src="`${require('@/assets/images/app_store2.png')}`"
                    alt="App Store"
                    style="height:auto;margin-top:5px;"
                  />
                </a>
              </div>
              <div class="ml-2">
                <a
                  class="d-block"
                  href="//play.google.com/store/apps/details?id=com.yitong.taifung.mbank.android"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b-img-lazy :src="`${require('@/assets/images/google_play.png')}`" alt="Google Play" />
                </a>
                <a
                  class="d-block"
                  href="//play.google.com/store/apps/details?id=com.yitong.taifung.mbank.android"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b-img-lazy
                    :src="`${require('@/assets/images/google_play2.png')}`"
                    alt="Google Play"
                    style="height:auto;margin-top:5px;"
                  />
                </a>
                <!-- <p class="text-center img-dec">Google Play</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bottom h-25 clearfix">
      <div class="container">
        <b-row>
          <b-col class="col-md-4 col-12">
            <span>{{ $t('©版權所有 大豐銀行') }} {{ year }}</span>
          </b-col>
          <b-col class="col-md-8 col-12 footer_bottom_right">
            <span>{{ $t('大豐銀行網站不會對客戶發送含有鏈接的郵件') }}</span>
          </b-col>
        </b-row>
        <div>
          <span>{{
            $t(
              '請使用1024×768像素或更高像素的顯示器且瀏覽器需執行在 Edge 80 / Chrome 80/ Firefox 74.0.1 / Safari 13或更高版本，以達到最佳效果。​'
            )
          }}</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import { ISO639ToLan } from '@/utils'

export default {
  name: 'layout-footer',
  data() {
    return {
      year: ''
    }
  },
  computed: {
    ...mapState('common', ['footers']),
    lang() {
      return ISO639ToLan(this.$getLocale())
    },
    footers_arr() {
      var result = []
      for (var i = 0; i < this.footers.menu2.length; i += 4) {
        result.push(this.footers.menu2.slice(i, i + 4))
      }
      return result
    }
  },
  mounted() {
    this.year = new Date().getFullYear()
  },
  filters: {
    url: function(footer, lang) {
      if (footer.url) {
        return footer.url
      } else if (footer.path) {
        return footer.path == 'home' ? `/${lang}/` : `/${lang}/${footer.path}`
      } else {
        return 'javascript:void(0)'
      }
    }
  }
}
</script>

<style scoped></style>
