<template>
  <div>
    <div v-if="popUp">
      <div v-if="popUp.content != '' && hide == 'show'">
        <b-container class="position-relative">
          <b-modal
            id="popup-modal-scoped"
            centered
            hide-footer
            hide-header
            :no-close-on-backdrop="false"
            body-class="p-3 popUp"
          >
            <div class="px-2 py-4">
              <div class="pb-2" v-html="popUp.title"></div>
              <div v-html="popUp.content"></div>
            </div>
            <b-button class="popUp-close" @click="handleHide"></b-button>
          </b-modal>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'
export default {
  name: 'popUp',
  props: {
    noticeData: Object
  },
  data() {
    return {
      hide: ''
    }
  },
  computed: {
    ...mapState('common', ['popUp'])
  },
  watch: {},
  mounted() {
    this.hide = sessionStorage.getItem('popUp')
    if (this.hide == null) {
      this.hide = 'show'
    }
    this.handleInit()
  },
  methods: {
    async handleInit() {
      window.scrollTo(0, 0)
      if (await this.popUp) {
        this.takeNoticeHeight()
      }
    },
    takeNoticeHeight() {
      this.$bvModal.show('popup-modal-scoped')
    },
    handleHide() {
      this.hide = 'hidden'
      sessionStorage.setItem('popUp', this.hide)
      setTimeout(() => {
        this.takeNoticeHeight()
      }, 500)
    }
  }
}
</script>

<style></style>
