import { FetchMarketRate } from '@/api/market_rate'
import { FetchMarketInfo } from '@/api/market_info'
import {
  FetchLinkList,
  FetchSearch,
  FetchHomepage,
  FetchNews,
  FetchBanners,
  FetchConvenientService,
  FetchNewsDetail,
  FetchCalendar,
  FetchDownloadList,
  FetchStatementsList,
  FetchActivityDetail
} from '@/api/page'

const state = {
  marketRate: null,
  marketInfo: null,
  links: null,
  searchs: null,
  homepage: null,
  banners: null,
  bannersMobile: null,
  homepageNavigation: null,
  news: null,
  newsDetails: null,
  promotion: null,
  product: null,
  convenientService: null,
  calendars: null,
  downloadList: null,
  statementslist: null,
  activity: null
}

const mutations = {
  SET_MARKET_RATE: (state, data) => {
    state.marketRate = data
  },
  SET_MARKET_INFO: (state, data) => {
    state.marketInfo = data
  },
  SET_LINKLIST: (state, data) => {
    state.links = data
  },
  SET_SEARCH: (state, data) => {
    state.searchs = data
  },
  SET_HOMEPAGE: (state, data) => {
    state.homepage = data
  },
  SET_BANNERS: (state, data) => {
    state.banners = data
  },
  SET_BANNERSMOBILE: (state, data) => {
    state.bannersMobile = data
  },
  SET_HOMEPAGE_NAVIGATION: (state, data) => {
    state.homepageNavigation = data
  },
  SET_NEWS: (state, data) => {
    state.news = data
  },
  SET_DETAIL: (state, data) => {
    state.newsDetails = data
  },
  SET_PROMOTION: (state, data) => {
    state.promotion = data
  },
  SET_PRODUCT: (state, data) => {
    state.product = data
  },
  SET_CONVENIENT_SERVICE: (state, data) => {
    state.convenientService = data
  },
  SET_CALENDAR: (state, data) => {
    state.calendars = data
  },
  SET_DOWNLOAD_LIST: (state, data) => {
    state.downloadList = data
  },
  SET_STATEMENTS_LIST: (state, data) => {
    state.statementslist = data
  },
  SET_ACTIVITY_DETAIL: (state, data) => {
    state.activity = data
  }
}

const actions = {
  FETCH_MARKET_RATE: ({ commit }, { cookies, preview, id }) => {
    return new Promise((resolve, reject) => {
      FetchMarketRate({ cookies, preview, id }).then(res => {
        if (res.code === 0) {
          commit('SET_MARKET_RATE', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_MARKET_INFO: ({ commit, state }, { cookies, preview, id }) => {
    // return !state.marketInfo
    //   ? FetchMarketInfo({ cookies, preview, id }).then(res => {
    //     if (res.code === 0) {
    //       commit('SET_MARKET_INFO', res.data)
    //     }
    //   })
    //   : null
    return new Promise((resolve, reject) => {
      FetchMarketInfo({ cookies, preview, id }).then(res => {
        if (res.code === 0) {
          commit('SET_MARKET_INFO', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_LINK_LIST: ({ commit }, { cookies, preview, id, vid }) => {
    return new Promise((resolve, reject) => {
      FetchLinkList({ cookies, preview, id, vid }).then(res => {
        if (res.code === 0) {
          commit('SET_LINKLIST', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_SEARCH: ({ commit }, { cookies, type, text, page, limit }) => {
    return new Promise((resolve, reject) => {
      FetchSearch({ cookies, type, text, page, limit }).then(res => {
        if (res.code === 0) {
          commit('SET_SEARCH', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_HOMEPAGE: ({ commit }, { cookies }) => {
    return new Promise((resolve, reject) => {
      FetchHomepage({ cookies }).then(res => {
        if (res.code === 0) {
          commit('SET_HOMEPAGE', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_BANNERS: ({ commit }, { cookies, slug, id, preview, vid }) => {
    return new Promise((resolve, reject) => {
      FetchBanners({ cookies, slug, id, preview, vid }).then(res => {
        if (res.code === 0) {
          commit('SET_BANNERS', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_BANNERSMOBILE: ({ commit }, { cookies, slug }) => {
    return new Promise((resolve, reject) => {
      FetchBanners({ cookies, slug }).then(res => {
        if (res.code === 0) {
          commit('SET_BANNERSMOBILE', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_HOMEPAGE_NAVIGATION: ({ commit }, { cookies, slug }) => {
    return new Promise((resolve, reject) => {
      FetchBanners({ cookies, slug }).then(res => {
        if (res.code === 0) {
          commit('SET_HOMEPAGE_NAVIGATION', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_NEWS: ({ commit }, { cookies, slug, limit, page }) => {
    return new Promise((resolve, reject) => {
      FetchNews({ cookies, slug, limit, page }).then(res => {
        if (res.code === 0) {
          commit('SET_NEWS', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_NEWS_DETAIL: ({ commit }, { cookies, id, preview, vid }) => {
    return new Promise((resolve, reject) => {
      FetchNewsDetail({ cookies, id, preview, vid }).then(res => {
        commit('SET_DETAIL', res.data)
        resolve()
      })
    })
  },
  FETCH_PROMOTION: ({ commit }, { cookies, slug, limit, page }) => {
    return new Promise((resolve, reject) => {
      FetchNews({ cookies, slug, limit, page }).then(res => {
        if (res.code === 0) {
          commit('SET_PROMOTION', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_PRODUCT: ({ commit }, { cookies, slug, limit, page }) => {
    return new Promise((resolve, reject) => {
      FetchNews({ cookies, slug, limit, page }).then(res => {
        if (res.code === 0) {
          commit('SET_PRODUCT', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_CONVENIENT_SERVICE: ({ commit }, { cookies, type }) => {
    return new Promise((resolve, reject) => {
      FetchConvenientService({ cookies, type }).then(res => {
        if (res.code === 0) {
          commit('SET_CONVENIENT_SERVICE', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_CALENDAR: ({ commit }, { cookies, year }) => {
    return new Promise((resolve, reject) => {
      FetchCalendar({ cookies, year }).then(res => {
        if (res.code === 0) {
          commit('SET_CALENDAR', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_DOWNLOAD_LIST: ({ commit }, { cookies, preview, id, vid }) => {
    return new Promise((resolve, reject) => {
      FetchDownloadList({ cookies, preview, id, vid }).then(res => {
        if (res.code === 0) {
          commit('SET_DOWNLOAD_LIST', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_STATEMENTS_LIST: ({ commit }, { cookies, preview, id, vid }) => {
    return new Promise((resolve, reject) => {
      FetchStatementsList({ cookies, preview, id, vid }).then(res => {
        if (res.code === 0) {
          commit('SET_STATEMENTS_LIST', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_ACTIVITY_DETAIL: ({ commit }, { cookies, preview, id, vid }) => {
    return new Promise((resolve, reject) => {
      FetchActivityDetail({ cookies, preview, id, vid }).then(res => {
        commit('SET_ACTIVITY_DETAIL', res.data)
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
