const getters = {
  banners: state => state.page.banners,
  bannersMobile: state => state.page.bannersMobile,
  homepageNavigation: state => state.page.homepageNavigation,
  news: state => state.page.news,
  newsDetail: state => state.page.newsDetail,
  promotion: state => state.page.promotion,
  product: state => state.page.product,
  convenientService: state => state.page.convenientService,
  setting: state => state.common.setting,
  homepage: state => state.page.homepage
}
export default getters
