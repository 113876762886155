import Vue from 'vue'
import Router from 'vue-router'
import { lanToISO639, lanToOtherISO639, isCSR } from '@/utils'
import i18n from '@/plugins/vue-i18n'
import MainLayout from '@/layouts/MainLayout'
import HomeLayout from '@/layouts/HomeLayout'
import { fetchSettings } from '@/api/common'
import { Validator } from 'vee-validate'
import moment from 'moment'
import 'moment/locale/zh-tw'
import 'moment/locale/zh-cn'
import store from '@/store'
Vue.use(Router)

const constantRoutes = [
  {
    path: '/en_US',
    redirect: '/en',
    children: [
      {
        path: 'contact_us',
        redirect: '/en/contact-us'
      },
      {
        path: 'bank-address',
        redirect: '/en/about-us/branches_atm_location'
      },
      {
        path: '*',
        redirect: '/en/'
      }
    ]
  },
  {
    path: '/zh_TW',
    redirect: '/zh-hant',
    children: [
      {
        path: 'contact_us',
        redirect: '/zh-hant/contact-us'
      },
      {
        path: 'bank-address',
        redirect: '/zh-hant/about-us/branches_atm_location'
      },
      {
        path: '*',
        redirect: '/zh-hant/'
      }
    ]
  },
  {
    path: '/zh_CN',
    redirect: '/zh-hans',
    children: [
      {
        path: 'contact_us',
        redirect: '/zh-hans/contact-us'
      },
      {
        path: 'bank-address',
        redirect: '/zh-hans/about-us/branches_atm_location'
      },
      {
        path: '*',
        redirect: '/zh-hans/'
      }
    ]
  },
  {
    path: '/:lang(zh-hant|zh-hans|en)',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        name: 'sitemap',
        path: 'sitemap',
        component: () => import('../views/sitemap/index')
      },
      {
        name: 'search',
        path: 'search',
        component: () => import('../views/search/index')
      },
      {
        name: 'news',
        path: 'news',
        component: () => import('../views/news/index')
      },
      {
        name: 'news-detail',
        path: 'news/:id',
        component: () => import('../views/news/detail')
      },
      {
        name: 'promotion',
        path: 'promotion',
        component: () => import('../views/promotion/index')
      },
      {
        name: 'promotion-detail',
        path: 'promotion/:id',
        component: () => import('../views/promotion/detail')
      },
      {
        name: 'product',
        path: 'product',
        component: () => import('../views/product/index')
      },
      {
        name: 'product-detail',
        path: 'product/:id',
        component: () => import('../views/product/detail')
      },
      {
        path: 'financial-information/market-price',
        name: 'market-rate',
        component: () => import('@/views/MarketRate.vue')
      },
      {
        path: 'financial-information/market-news',
        name: 'market-info',
        component: () => import('@/views/MarketInfo.vue')
      },
      {
        path: 'financial-information/market-notice',
        name: 'market-notice',
        component: () => import('@/views/news/index')
      },
      {
        path: 'financial-information/market-notice/:id',
        name: 'market-notice-detail',
        component: () => import('../views/news/detail')
      },
      {
        name: 'join-us',
        path: 'join-us',
        component: () => import('../views/joinUs/index')
      },
      {
        name: 'join-us-form',
        path: 'join-us/job-opportunity',
        component: () => import('../views/joinUs/form')
      },
      {
        name: 'downloads',
        path: 'downloads',
        component: () => import('../views/downLoad/index')
      },
      {
        name: 'useful-links',
        path: 'useful-links',
        component: () => import('../views/useful-links/index')
      },
      {
        name: 'calculator',
        path: 'calculator',
        component: () => import('../views/calculator/index')
      },
      {
        name: 'activity-detail',
        path: 'activity/:id',
        component: () => import('../views/activity/detail')
      },
      {
        name: 'activity-table-form',
        path: 'activity-table-form',
        component: () => import('../views/activity/tableForm')
      },
      {
        path: 'debug',
        name: 'debug',
        component: () => import('@/views/Debug.vue')
      },
      {
        name: 'appinfo',
        path: 'appinfo',
        component: () => import('../views/Debug.vue')
      }
    ]
  },
  {
    path: '/:lang(zh-hant|zh-hans|en)/about-us',
    component: MainLayout,
    children: [
      {
        name: 'branches_atm_location',
        path: 'branches_atm_location',
        component: () => import('@/views/GoogleMap.vue')
      },
      {
        name: 'bank_holiday',
        path: 'bank_holiday',
        component: () => import('../views/activity/index')
      },
      {
        name: 'financial-information',
        path: 'financial-information',
        component: () => import('../views/statements/index')
      },
      // {
      //   name: 'history',
      //   path: 'history',
      //   component: () => import('../views/history/')
      // },
      {
        name: 'about-us',
        path: '*',
        component: () => import('../views/Page.vue')
      }
    ]
  },
  {
    path: '/:lang(zh-hant|zh-hans|en)/preview',
    component: MainLayout,
    children: [
      {
        name: 'preview-download',
        path: 'download',
        redirect: { name: 'downloads' }
      },
      {
        name: 'preview-link',
        path: 'link',
        redirect: { name: 'useful-links' }
      },
      {
        name: 'preview-banner',
        path: 'banner',
        component: () => import('../views/preview/banner.vue')
      },
      {
        name: 'preview-job',
        path: 'job',
        redirect: { name: 'join-us' }
      },
      {
        name: 'preview-calendar',
        path: 'calendar',
        component: () => import('../views/activity/detail')
      },
      {
        name: 'preview-news',
        path: 'news',
        component: () => import('../views/news/detail')
      },
      {
        name: 'preview-bank',
        path: 'bank',
        redirect: { name: 'branches_atm_location' }
      },
      {
        name: 'preview-page',
        path: '*',
        redirect: { name: 'other-page' }
      }
    ]
  },
  {
    path: '/:lang(zh-hant|zh-hans|en)/dynamic-form',
    component: MainLayout,
    children: [
      {
        name: 'form-type',
        path: '*',
        component: () => import('../views/dynamicForm/index')
      }
    ]
  },
  {
    path: '/:lang(zh-hant|zh-hans|en)',
    component: MainLayout,
    children: [
      {
        name: 'contact-us',
        path: 'contact-us',
        component: () => import('../views/dynamicForm/index')
      },
      {
        name: 'cdn-link',
        path: 'cdn-link/:id',
        component: () => import('@/views/cdnLink/index')
      },
      {
        name: 'other-page',
        path: '*',
        component: () => import('@/views/Page.vue')
      }
    ]
  },
  {
    path: '/:lang(zh-hant|zh-hans|en)',
    component: MainLayout,
    children: [
      {
        name: '404',
        path: '/404',
        component: () => import('@/views/error-page/Error404.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: '/zh-hant/'
  },
  {
    path: '*',
    redirect: '/404'
  }
]
export function createRouter() {
  let firstInRouter = false
  const router = new Router({
    mode: 'history',
    fallback: false,
    routes: constantRoutes,
    scrollBehavior: () => ({ y: 0 })
  })

  router.beforeEach(async (to, from, next) => {
    const locale = to.params.lang
    if (locale) {
      console.log(i18n.locale, lanToISO639(locale), locale)
      i18n.locale = lanToISO639(locale)
      Validator.localize(lanToISO639(locale))
      moment.locale(lanToOtherISO639(locale))
    }
    if (!firstInRouter) {
      fetchSettings({ identify: 'config', ck: 'general-settings' }).then(res => {
        if (res.code === 0) {
          firstInRouter = true
          //           const head = document.head || document.getElementsByTagName('head')[0];
          // console.log(res.data.content)
          // head.innerHTML += res.data.content

          if (isCSR()) {
            sessionStorage.setItem('setting', JSON.stringify(res.data))
            console.log(res.data)
          }
          next()
        }
      })
    } else {
      next()
    }
    //
  })
  router.afterEach(async (to, from, next) => {
    if (isCSR()) {
      window.gtag('config', JSON.parse(sessionStorage.getItem('setting'))['google-analytics-key'], {
        page_path: to.fullPath
      })
    }
  })

  return router
}
