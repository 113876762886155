import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'intersection-observer'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import store from '@/store'
// console.log('123123object',store.getters)
if (process.env.VUE_ENV === 'client') {
  require('bootstrap/dist/css/bootstrap.min.css')
  require('bootstrap-vue/dist/bootstrap-vue.css')
}

Vue.use(BootstrapVue)
