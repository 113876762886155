<template>
  <div>
    <div v-if="notice">
      <!--<div class="notice p-2 p-md-4" v-if="notice.content != '' && hide == 'show'">
        <b-container class="position-relative">
          <h2 class="pb-2">{{ notice.title }}</h2>
          <div class="content col-md-9 col-10" v-html="notice.content"></div>
          <b-button class="close" @click="handleHide"></b-button>
        </b-container>
      </div>-->
      <div class="notice" v-if="notice.content && notice.content != '' && hide == 'show'">
        <div v-html="notice.content"></div>
        <b-button class="close" @click="handleHide"></b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'
export default {
  name: 'notice',
  props: {
    noticeData: Object
  },
  data() {
    return {
      hide: '',
      fullWidth: null
    }
  },
  computed: {
    ...mapState('common', ['notice', 'menu'])
  },
  watch: {
    fullWidth(val) {
      if (!this.timer) {
        this.fullWidth = val
        this.timer = true
        let that = this
        setTimeout(function() {
          that.timer = false
          that.takeNoticeHeight()
        }, 400)
      }
    }
  },
  mounted() {
    this.hide = sessionStorage.getItem('notice')
    if (this.hide == null) {
      this.hide = 'show'
    }
    this.handleInit()
    let that = this
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth
        that.fullWidth = window.fullWidth
      })()
    }
  },
  methods: {
    async handleInit() {
      window.scrollTo(0, 0)
      if (await this.notice) {
        this.takeNoticeHeight()
      }
    },
    takeNoticeHeight() {
      $('.navbar-nav .dropdown-menu:not(.small)').css({
        // top: ($('.main-container .notice').outerHeight() || 0) + 136 + 'px'
        top: ($('.main-container .header .menu_main').outerHeight() || 0) + 34 + 'px'
        // top: 136 + 'px'
      })
      $('.menu .nav_mb .mb_dropdown').css({
        // top: ($('.main-container .notice').outerHeight() || 0) + 168 + 'px'
        top: 168 + 'px'
      })
      $('.header').css({
        top: ($('.main-container .notice').outerHeight() || 0) + 0 + 'px'
      })
      // $('.main-container').css({
      //   // paddingTop: ($('.main-container .notice').outerHeight() || 0) + 149 + 'px'
      //   marginTop: (($('.nav_pc').css('display') == 'block' && $('.navbar-nav .dropdown-menu:not(.small)').css('top') == '120px')? (($('.main-container .notice').outerHeight() || 0) - 16) : (($('.main-container .notice').outerHeight() || 0) + 20)) + 149 + 'px'
      // })
      $('.main-container').css({
        // paddingTop: ($('.main-container .notice').outerHeight() || 0) + 149 + 'px'
        marginTop:
          ($('.nav_pc').css('display') == 'block'
            ? $('.navbar-nav .dropdown-menu:not(.small)').css('top') == '120px'
              ? ($('.main-container .notice').outerHeight() || 0) - 16
              : $('.main-container .notice').outerHeight() || 0
            : ($('.main-container .notice').outerHeight() || 0) + 20) +
          149 +
          'px'
      })
    },
    handleHide() {
      this.hide = 'hidden'
      sessionStorage.setItem('notice', this.hide)
      setTimeout(() => {
        this.takeNoticeHeight()
      }, 500)
    }
  }
}
</script>

<style></style>
