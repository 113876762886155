<template>
  <div
    :class="[
      'app-wrapper',
      `page-${$route.name}`,
      `lang-${$i18n.locale}`,
      `${isScroll ? 'scroll' : ''}`,
      template
      // grayStyle ? 'gray-screen' : ''
    ]"
  >
    <div class="main-container">
      <Notice v-if="name == 'home' ? true : false" />
      <Header :key="$route.fullPath" v-if="isMounted" />
      <Breadcrumb v-if="name == 'home' || name == '404' ? false : true" />
      <div class="main">
        <transition name="fade-transform" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
      </div>
      <Footer />
      <PopUp v-if="name == 'home' ? true : false" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from './components/Header'
import Footer from './components/Footer'
import Breadcrumb from './components/Breadcrumb'
import Notice from './components/Notice'
import PopUp from './components/PopUp'
// import { fetchTemplate } from '@/api/common'
export default {
  name: 'main-layout',
  data() {
    return {
      isMounted: false,
      isScroll: false,
      hTemplate: ''
    }
  },
  components: {
    Header,
    Footer,
    Breadcrumb,
    Notice,
    PopUp
  },
  computed: {
    ...mapState('common', ['menu', 'template', 'grayStyle']),
    alreadyInited: function() {
      return !!this.menu
    },
    key() {
      return this.$route.path
    },
    name() {
      return this.$route.name
    }
  },
  // serverPrefetch() {
  //   return this.fetchindexInitData()
  // },
  mounted() {
    this.isMounted = true
    var that = this
    function getBodyScrollTop() {
      const el = document.scrollingElement || document.documentElement
      return el.scrollTop
    }
    // fetchTemplate().then(res => {
    //   this.template = res.data
    // })
    // this.fetchindexInitData()
    window.addEventListener('scroll', function() {
      var scrollTop = getBodyScrollTop()
      if (scrollTop > 0) {
        that.isScroll = true
      } else {
        that.isScroll = false
      }
    })
  },
  methods: {
    // fetchindexInitData() {
    //   const cookies = this.$ssrContext && this.$ssrContext.cookies
    //   return new Promise((resolve, reject) => {
    //     Promise.all([this.fetchInitData()])
    //       .finally(() => {
    //         resolve()
    //       })
    //       .catch(reject)
    //   })
    // }
  }
}
</script>
