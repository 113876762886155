import request, { getCommonHeader } from '@/utils/request'

export function fetchMenu() {
  return request.get('/api/v1/menu/items')
}

export function fetchFooter(mark) {
  return request.get('/api/v1/menu/items', { params: { mark } })
}

export function fetchLayout() {
  return request.get('/api/v1/bff/common')
}

/**
 * 拉取页面内容
 * @param {*} params
 */
export function fetchPage({ path, cookies, preview, id, vid }) {
  return request.get('/api/v1/page/item', {
    headers: getCommonHeader({ cookies }),
    params: { path, preview, id, vid }
  })
}

/**
 * 通知
 * @param {*} params
 */
export function fetchNotice({ slug }) {
  return request.get('/api/v1/notice', {
    params: { slug }
  })
}

/**
 * message_template
 */
export function fetchHeadEndCode() {
  return request.get('/api/v1/common/message_template?alias=_head_end_code')
}
/**
 * message_template
 */
export function fetchSelection(params) {
  return request.get('/api/v1/common/selection', {
    params
  })
}

/**
 * settings Settings
 */
export function fetchSettings(params) {
  return request.get('/api/v1/common/setting', {
    params
  })
}
/**
 * settings Settings
 */
export function fetchSystemSettings(params) {
  return request.get('/api/v1/common/system/setting', {
    params
  })
}
/**
 * link Settings
 */
export function fetchLink(params) {
  return request.get(`/api/v1.0/link/identity/${params.id}`)
}
