require('@/plugins/bootstrap-vue')
require('@/plugins/vue-i18n')

import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import i18n from '@/plugins/vue-i18n'
import 'babel-polyfill'
import 'es6-promise/auto'
import App from './App.vue'
import GlobalMixin from '@/mixins'
import { createRouter } from './router'
import { createStore } from './store'
import VeeValidate, { Validator } from 'vee-validate'
import rules from './rules'
import dictionary from './locales/validations'

import * as filters from '@/plugins/filters.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]) //插入过滤器名和对应方法
})

if (process.env.VUE_ENV === 'client') {
  require('@/assets/css/common.scss')
  require('vue2-animate/dist/vue2-animate.min.css')
  require('@/plugins/vue-awesome-swiper.js')
}

for (const key in rules) {
  Validator.extend(key, rules[key])
}
Vue.use(VeeValidate)
Validator.localize('zh_TW')
Validator.localize(dictionary)

Vue.mixin(GlobalMixin)

// if (typeof window !== 'undefined') {
// }

//关闭生产模式下给出的提示
Vue.config.productionTip = true

// 控制服务端渲染时候，返回HTML移除三方css，转为 preload 加载，前提: 默认支持的preload加载


export function createApp() {
  const router = createRouter()
  const store = createStore()
  // sync the router with the vuex store.
  // this registers `store.state.route`
  sync(store, router)
  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  })
  return { app, router, store }
}

// promise.finally Polyfill
if (!Promise.prototype.finally) {
  Promise.prototype.finally = function (callback) {
    let P = this.constructor
    return this.then(
      value => P.resolve(callback()).then(() => value),
      reason =>
        P.resolve(callback()).then(() => {
          throw reason
        })
    )
  }
}
