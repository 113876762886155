import { FetchApplyJobs, FetchRegionList, FetchNationList } from '@/api/join_us'

const state = {
  joinUs: {
    jobs: [],
    region: [],
    nation: []
  },
  joinUsOneForm: {},
  joinUsTwoForm: {},
  joinUsThirdForm: {},
  joinUsFourthForm: {}
}

const mutations = {
  SET_APPLY_JOBS: (state, data) => {
    state.joinUs.jobs = data
  },
  SET_REGION_LIST: (state, data) => {
    state.joinUs.region = data
  },
  SET_NATION_LIST: (state, data) => {
    state.joinUs.nation = data
  },
  SET_JOINUS_ONEFORM: (state, data) => {
    state.joinUsOneForm = Object.assign({}, data)
  },
  SET_JOINUS_TWOFORM: (state, data) => {
    state.joinUsTwoForm = Object.assign({}, data)
  },
  SET_JOINUS_THIRDFORM: (state, data) => {
    state.joinUsThirdForm = Object.assign({}, data)
  },
  SET_JOINUS_FOURTHFORM: (state, data) => {
    state.joinUsFourthForm = Object.assign({}, data)
  },
}

const actions = {
  SET_One_Form: ({ commit }, data) => {
    commit('SET_JOINUS_ONEFORM', data)
  },
  SET_Two_Form: ({ commit }, data) => {
    commit('SET_JOINUS_TWOFORM', data)
  },
  SET_Third_Form: ({ commit }, data) => {
    commit('SET_JOINUS_THIRDFORM', data)
  },
  SET_Fourth_Form: ({ commit }, data) => {
    commit('SET_JOINUS_FOURTHFORM', data)
  },
  FETCH_APPLY_JOBS: ({ commit }, { cookies, params }) => {
    return new Promise(resolve => {
      FetchApplyJobs({ cookies, params }).then(res => {
        if (res.code === 0) {
          commit('SET_APPLY_JOBS', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_REGION_LIST: ({ commit }, { cookies, params }) => {
    return new Promise(resolve => {
      FetchRegionList({ cookies, params }).then(res => {
        if (res.code === 0) {
          commit('SET_REGION_LIST', res.data)
          resolve()
        }
      })
    })
  },
  FETCH_NATION_LIST: ({ commit }, { cookies, params }) => {
    return new Promise(resolve => {
      FetchNationList({ cookies, params }).then(res => {
        if (res.code === 0) {
          commit('SET_NATION_LIST', res.data)
          resolve()
        }
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
