export default {
  methods: {
    $getLocale() {
      return this.$i18n.locale
    },
    $switchLanguage(locale) {
      var redirect
      if (Object.keys(this.$route.query).length != 0) {
        redirect =
          this.$route.path.replace(`${this.$route.params.lang}`, locale) + '?' + window.location.href.split('?')[1]
      } else {
        redirect = this.$route.path.replace(`${this.$route.params.lang}`, locale)
      }
      window.location = redirect
    },
    fetchInitData() {
      return Promise.all([
        this.$store.dispatch('common/FETCH_LAYOUT'),
        // this.$store.dispatch('common/FETCH_MENU'),
        // this.$store.dispatch('common/FETCH_FOOTER', 'menu1'),
        // this.$store.dispatch('common/FETCH_FOOTER', 'menu2'),
        this.$store.dispatch('common/FETCH_NOTICE', { slug: 'important-notice' }),
        this.$store.dispatch('common/FETCH_POPUP', { slug: 'popup-notice' }),
        this.$store.dispatch('common/FETCH_TEMPLATE'),
        this.$store.dispatch('common/FETCH_HOME_ACTIVITY'),
        this.$store.dispatch('common/FETCH_TOP_PHONE')
      ])
    }
  }
}
