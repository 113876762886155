import zhHantLocale from './zhHant'
import zhHansLocale from './zhHans'
import enLocale from './en'

var locale = {
  zh_TW: {
    ...zhHantLocale
  },
  zh_CN: {
    ...zhHansLocale
  },
  en: {
    ...enLocale
  }
}

export default locale
