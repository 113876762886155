import axios from 'axios'
// import fs from 'fs'
// import https from 'https';

import i18n from '@/plugins/vue-i18n'

const TARGET_SERVER = process.env.VUE_ENV === 'server'
const baseUrl = TARGET_SERVER ? process.env.config.API_HOST : null

const instance = axios.create({
  baseURL: baseUrl,
  // httpsAgent
})

// const httpsAgent = new https.Agent({
//   rejectUnauthorized: false, // (NOTE: this will disable client verification)
//   cert: fs.readFileSync("./usercert.pem"),
//   key: fs.readFileSync("./key.pem"),
//   passphrase: "YYY"
// })
// axios.get(url, { httpsAgent })


instance.interceptors.request.use(config => {
  config.headers = Object.assign({}, config.headers, { 'Api-Language': i18n.locale })
  if(process.env.config.PREVIEW=='false'){
    try{
      if(config.params.preview){
        config.params.id = ''
      }
    }
    catch{}
  }
  return config
})

instance.interceptors.response.use(response => {
  
  if (/^[4|5]/.test(response.status)) {
    return Promise.reject(response.statusText)
  }
  return response.data
})

export default instance

export function getCommonHeader({ cookies }) {
  if (TARGET_SERVER && cookies) {
    return { cookie: getCookieString(cookies) }
  } else {
    return null
  }
}

function getCookieString(cookies) {
  let cookieStr = ''
  for (var variable in cookies) {
    // eslint-disable-next-line no-prototype-builtins
    if (cookies.hasOwnProperty(variable)) {
      cookieStr += `${variable}=${encodeURIComponent(cookies[variable])}; `
    }
  }
  return cookieStr
}
