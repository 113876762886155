export default {
  zh_TW: {
    messages: {
      required: '必須填寫',
      uploadRequired: '必須上傳',
      phone: '請輸入正確的澳門電話',
      userPhone: '請輸入正確的澳門電話',
      email: '請輸入正確的電郵',
      link: '必須以 http:// 或 https:// 開頭',
      passverification: '密碼必須包含8-20字符',
      timeEvent: '请输入正确的时间',
      idNumber: '請輸入正確的身份證號碼',
      noTrim: '不能有空格',
      position: '請輸入數字'
    },
    custom: {
      repeatPassword: {
        required: '必須填寫',
        confirmed: '兩次密碼不一致'
      },
      chinesename: {
        required: '必須填寫',
        chinese: '請輸入中文'
      },
      foreignname: {
        required: '必須填寫',
        englist: '請輸入英文'
      },
      addressZh: {
        required: '必須填寫',
        min: '至少5個字符'
      },
      addressEn: {
        required: '必須填寫',
        min: '至少5個字符'
      },
      type: {
        required: '請選擇上傳鏈接類型'
      },
      businessRegistration: {
        required: '必須上傳'
      },
      logo: {
        required: '必須上傳'
      },
      cover: {
        required: '必須上傳'
      },
      diplomaFile: {
        required: '必須上傳'
      },
      photo: {
        required: '必須上傳'
      },
      banner: {
        required: '必須上傳'
      },
      license: {
        required: '必須上傳'
      }
    }
  },
  zh_CN: {
    messages: {
      required: '必须填写',
      uploadRequired: '必须上传',
      phone: '请输入正确的澳门电话',
      userPhone: '请输入正确的澳门电话',
      email: '请输入正确的电邮',
      link: '必须以 http:// 或 https:// 开头',
      idNumber: '请输入正确的身份证号码',
      passverification: '密碼必須包含8-20字符',
      noTrim: '不能有空格',
      position: '请输入数字'
    },
    custom: {
      repeatPassword: {
        required: '必须填写',
        confirmed: '两次密码不一致'
      },
      chinesename: {
        required: '必须填写',
        chinese: '请输入中文'
      },
      foreignname: {
        required: '必须填写',
        englist: '请输入英文'
      },
      addressZh: {
        required: '必须填写',
        min: '至少5个字符'
      },
      addressEn: {
        required: '必须填写',
        min: '至少5个字符'
      },
      type: {
        required: '请选择上传连接类型'
      },
      businessRegistration: {
        required: '必须上传'
      },
      logo: {
        required: '必须上传'
      },
      cover: {
        required: '必须上传'
      },
      diplomaFile: {
        required: '必須上传'
      },
      photo: {
        required: '必須上传'
      },
      banner: {
        required: '必须上传'
      },
      license: {
        required: '必须上传'
      }
    }
  },
  en: {
    messages: {
      required: 'Required',
      uploadRequired: 'Must upload',
      phone: 'Please input the correct Macao phone number',
      userPhone: 'Please input the correct Macao phone number',
      link: 'Must start with http:// or https://',
      idNumber: 'Please enter a valid ID number',
      passverification: 'The password must be 8 to 20 characters'
    },
    custom: {
      repeatPassword: {
        required: 'Required',
        confirmed: 'Two passwords are inconsistent'
      },
      chinesename: {
        required: 'Required',
        chinese: 'Please enter Chinese'
      },
      foreignname: {
        required: 'Required',
        englist: 'Please enter English'
      },
      addressZh: {
        required: 'Required',
        min: 'At least 5 characters'
      },
      addressEn: {
        required: 'Required',
        min: 'At least 5 characters'
      },
      type: {
        required: 'Please select upload connection type'
      },
      businessRegistration: {
        required: 'Must upload'
      },
      logo: {
        required: 'Must upload'
      },
      cover: {
        required: 'Must upload'
      },
      diplomaFile: {
        required: 'Must upload'
      },
      photo: {
        required: 'Must upload'
      },
      banner: {
        required: 'Must upload'
      },
      license: {
        required: 'Must upload'
      }
    }
  }
}
