var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'app-wrapper',
    ("page-" + (_vm.$route.name)),
    ("lang-" + (_vm.$i18n.locale)),
    ("" + (_vm.isScroll ? 'scroll' : '')),
    _vm.template
    // grayStyle ? 'gray-screen' : ''
  ]},[_c('div',{staticClass:"main-container"},[(_vm.name == 'home' ? true : false)?_c('Notice'):_vm._e(),(_vm.isMounted)?_c('Header',{key:_vm.$route.fullPath}):_vm._e(),(_vm.name == 'home' || _vm.name == '404' ? false : true)?_c('Breadcrumb'):_vm._e(),_c('div',{staticClass:"main"},[_c('transition',{attrs:{"name":"fade-transform","mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),_c('Footer'),(_vm.name == 'home' ? true : false)?_c('PopUp'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }