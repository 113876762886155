import request, { getCommonHeader } from '@/utils/request'

/**
 * 获取link
 * @param {*} params
 */
export function FetchLinkList({ path, cookies, preview, id, vid }) {
  return request.get('/api/v1/link?type=friendship-link', {
    headers: getCommonHeader({ cookies }),
    params: { path, preview, id, vid }
  })
}

/**
 * 搜索
 * @param {*} params
 */
export function FetchSearch({ cookies, type, text, page, limit }) {
  return request.get('/api/v1/search/list', {
    headers: getCommonHeader({ cookies }),
    params: { type, text, page, limit }
  })
}

/**
 * 拉取Homepage
 * @param {*} params
 */
export function FetchHomepage({ cookies }) {
  return request.get('/api/v1/bff/homepage', {
    headers: getCommonHeader({ cookies }),
    params: {}
  })
}

/**
 * 拉取News
 * @param {*} params
 */
export function FetchBanners({ cookies, slug, id, preview, vid }) {
  return request.get('/api/v1/banner/list', {
    headers: getCommonHeader({ cookies }),
    params: { slug, id, preview, vid }
  })
}

/**
 * 拉取News
 * @param {*} params
 */
export function FetchNews({ cookies, slug, limit, page }) {
  return request.get('/api/v1/news/list', {
    headers: getCommonHeader({ cookies }),
    params: { slug, limit, page }
  })
}

/**
 * 拉取便捷服務
 * @param {*} params
 */
export function FetchConvenientService({ cookies, type }) {
  return request.get('/api/v1/link', {
    headers: getCommonHeader({ cookies }),
    params: { type }
  })
  // return request({
  //     url: '/api/v1/link',
  //     method: 'get',
  //     params
  // })
}

/**
 * 拉取New Detail
 * @param {*} params
 */
export function FetchNewsDetail({ cookies, id, preview, vid }) {
  return request.get('/api/v1/news/item', {
    headers: getCommonHeader({ cookies }),
    params: { id, preview, vid }
  })
}

/**
 * 日歷
 * @param {*} params
 */
export function FetchCalendar({ cookies, year }) {
  return request.get('/api/v1/calendar/list', {
    headers: getCommonHeader({ cookies }),
    params: { year }
  })
}

/**
 * 获取下載
 * @param {*} params
 */
export function FetchDownloadList({ cookies, preview, id, vid }) {
  return request.get('/api/v1/download?slug=file-download', {
    headers: getCommonHeader({ cookies }),
    params: { preview, id, vid }
  })
}

/**
 * 获取財務報表
 * @param {*} params
 */
export function FetchStatementsList({ cookies, preview, id, vid }) {
  return request.get('/api/v1/download?slug=financial-information', {
    headers: getCommonHeader({ cookies }),
    params: { preview, id, vid }
  })
}

/**
 * 活動詳情
 * @param {*} params
 */
export function FetchActivityDetail({ cookies, preview, id, vid }) {
  return request.get('/api/v1/calendar/activity', {
    headers: getCommonHeader({ cookies }),
    params: { preview, id, vid }
  })
}
