<template>
  <div class="menu">
    <b-navbar toggleable="lg" type="light" class="nav_pc">
      <div class="nav_item">
        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-for="menu in menus" :key="`menu-${menu.id}`" class="text-dark">
            <template v-if="menu && menu.chilrends && menu.chilrends.length > 0">
              <li
                v-if="
                  menu.chilrends.length <= 3 &&
                    menu.chilrends
                      .map(e => e.chilrends.length)
                      .reduce((prev, curr, idx, arr) => {
                        return prev + curr
                      }) === 0
                "
                class="dropdown"
                style="position: relative"
              >
                <a
                  href="javascript:;"
                  class="dropdown-toggle text-dark nav-font-size"
                  data-toggle="dropdown"
                  rel="noflow"
                  v-if="menu.title"
                >
                  {{ menu.title }}
                </a>
                <div
                  class="dropdown-box dropdown-menu menu-top small"
                  style="width: auto; top: 93%; left: 50%; position: absolute; transform: translateX(-50%)"
                >
                  <div class="small-box">
                    <ul>
                      <li
                        v-for="menuLc in menu.chilrends"
                        :key="`menuLc-${menuLc.id}`"
                        :class="[menuLc.chilrends.length > 0 ? 'dropdown-submenu d-flex flex-column' : '']"
                      >
                        <template v-if="menuLc.url">
                          <b-link
                            rel=""
                            tabindex="-1"
                            :href="menuLc | url(lang)"
                            :target="menuLc.newWindow ? '_blank' : ''"
                            v-if="menuLc && menuLc.title"
                          >
                            <b-dropdown-group
                              tabindex="-1"
                              href="javascript:;"
                              :header="menuLc.title"
                              rel="noflow"
                            ></b-dropdown-group>
                            <b-dropdown-divider></b-dropdown-divider>
                          </b-link>
                        </template>
                        <template v-else>
                          <b-link rel="" tabindex="-1" :to="menuLc | url(lang)" v-if="menuLc && menuLc.title">
                            <b-dropdown-group
                              tabindex="-1"
                              href="javascript:;"
                              :header="menuLc.title"
                              rel="noflow"
                            ></b-dropdown-group>
                            <b-dropdown-divider></b-dropdown-divider>
                          </b-link>
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li v-else class="dropdown">
                <a
                  href="javascript:;"
                  class="dropdown-toggle text-dark nav-font-size"
                  data-toggle="dropdown"
                  rel="noflow"
                  v-if="menu.title"
                >
                  {{ menu.title }}
                </a>
                <div class="dropdown-box dropdown-menu menu-top">
                  <div class="menu-content">
                    <ul
                      class="content_ul"
                      :class="[
                        `${menu.chilrends.length < 1 ? 'flex-row' : ''}`,
                        `${
                          menu.chilrends
                            .map(e => e.chilrends.length)
                            .reduce((prev, curr, idx, arr) => {
                              return prev + curr
                            }) < 4
                            ? 'on'
                            : 'off'
                        }`
                      ]"
                    >
                      <li
                        v-for="menuLc in menu.chilrends"
                        :key="`menuLc-${menuLc.id}`"
                        :class="[menuLc.chilrends.length > 0 ? 'dropdown-submenu d-flex flex-column' : '']"
                      >
                        <template v-if="menuLc && menuLc.chilrends && menuLc.chilrends.length > 0">
                          <b-dropdown-group
                            tabindex="-1"
                            href="javascript:;"
                            :header="menuLc.title"
                            rel="noflow"
                            v-if="menuLc.title"
                          ></b-dropdown-group>
                          <b-dropdown-divider v-if="menuLc.title"></b-dropdown-divider>
                          <ul class="last_ul">
                            <li
                              id="dropdown-group-1"
                              :header="menuLc.title"
                              v-for="menuLcc in menuLc.chilrends"
                              :key="`menuLcc-${menuLcc.id}`"
                            >
                              <template v-if="menuLcc.url">
                                <b-link
                                  :href="menuLcc.url ? menuLcc.url : ''"
                                  v-if="menuLcc && menuLcc.title"
                                  :target="menuLcc.newWindow ? '_blank' : ''"
                                >
                                  <!-- <b-dropdown-item-button class="button_hover"> -->
                                  <div class="a_hover">{{ menuLcc.title }}</div>
                                  <!-- </b-dropdown-item-button> -->
                                </b-link>
                              </template>
                              <template v-else>
                                <b-link
                                  :to="menuLcc | url(lang)"
                                  v-if="menuLcc && menuLcc.title"
                                  :target="menuLcc.newWindow ? '_blank' : ''"
                                >
                                  <!-- <b-dropdown-item-button class="button_hover"> -->
                                  <div class="a_hover">{{ menuLcc.title }}</div>
                                  <!-- </b-dropdown-item-button> -->
                                </b-link>
                              </template>
                            </li>
                          </ul>
                        </template>
                        <template v-else>
                          <template v-if="menuLc.url">
                            <b-link
                              tabindex="-1"
                              :href="menuLc.url ? menuLc.url : ''"
                              :target="menuLc.newWindow ? '_blank' : ''"
                              v-if="menuLc && menuLc.title"
                            >
                              <b-dropdown-group
                                tabindex="-1"
                                href="javascript:;"
                                :header="menuLc.title"
                                rel="noflow"
                              ></b-dropdown-group>
                              <b-dropdown-divider></b-dropdown-divider>
                            </b-link>
                          </template>
                          <template v-else>
                            <b-link
                              tabindex="-1"
                              :to="menuLc | url(lang)"
                              :target="menuLc.newWindow ? '_blank' : ''"
                              v-if="menuLc && menuLc.title"
                            >
                              <b-dropdown-group
                                tabindex="-1"
                                href="javascript:;"
                                :header="menuLc.title"
                                rel="noflow"
                              ></b-dropdown-group>
                              <b-dropdown-divider></b-dropdown-divider>
                            </b-link>
                          </template>
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </template>
            <template v-else>
              <b-nav-item :to="menu | url(lang)" class="nav_head_item text-dark" v-if="menu && menu.title">{{
                menu.title
              }}</b-nav-item>
            </template>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>

    <b-navbar toggleable="lg" type="light" class="nav_mb" v-if="isMounted">
      <div class="nav_item">
        <div class="w-100 text-right pt-3 toggle_btn">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        </div>
        <b-collapse is-nav id="nav-collapse" class="text-center mb_dropdown">
          <b-navbar-nav>
            <template v-for="menu in menus">
              <template v-if="menu && menu.chilrends && menu.chilrends.length > 0">
                <b-nav-item-dropdown :key="menu.id" tag="ul">
                  <template slot="button-content">
                    <span @click="pushRoute(menu.id, menu.url)" v-if="menu.title">{{ menu.title }}</span>
                  </template>
                  <b-list-group tag="ul">
                    <!-- <template> -->
                    <b-list-group-item :key="`sub-${sub.id}`" v-for="sub in menu.chilrends" tag="li">
                      <template v-if="sub && sub.chilrends && sub.chilrends.length > 0">
                        <b-button class="collapse_button" @click="showSub(sub.id)" v-if="sub.title">{{
                          sub.title
                        }}</b-button>
                        <b-collapse :id="sub.id.toString()">
                          <b-list-group tag="ul">
                            <li class="third_li" v-for="item in sub.chilrends" :key="`item-${item.id}`">
                              <div v-if="item.title">
                                <template v-if="item.url">
                                  <b-link
                                    rel=""
                                    :href="item | url(lang)"
                                    :target="item.newWindow ? '_blank' : ''"
                                    class="nav-link"
                                    >{{ item.title }}</b-link
                                  >
                                </template>
                                <template v-else>
                                  <b-link rel="" :to="item | url(lang)" class="nav-link">{{ item.title }}</b-link>
                                </template>
                              </div>
                            </li>
                          </b-list-group>
                        </b-collapse>
                        <!-- <p @click.stop="showSub">{{ sub['title'] }}</p>
                        <b-list-group tag="ul" v-show="OpenSub">
                          <li
                            class="third_li"
                            v-for="item in sub['chilrends']"
                            :key="`item-${item['id']}`"
                          >
                            <a rel="" :href="item | url(lang)">{{ item.title }}</a>
                          </li>
                        </b-list-group> -->
                      </template>
                      <template v-else>
                        <ul>
                          <b-nav-item :to="sub | url(lang)" v-if="sub && sub.title">{{ sub.title }}</b-nav-item>
                        </ul>
                      </template>
                    </b-list-group-item>
                    <!-- </template> -->
                  </b-list-group>
                </b-nav-item-dropdown>
              </template>
              <template v-else>
                <b-nav-item
                  class="first_nav_item"
                  :key="`menu-${menu.id}`"
                  :to="menu | url(lang)"
                  v-if="menu && menu.title"
                  >{{ menu.title }}</b-nav-item
                >
              </template>
            </template>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ISO639ToLan } from '@/utils'

export default {
  // inject: ['reload'],
  name: 'layout-menu',
  data() {
    return {
      OpenSub: false,
      isMounted: false
    }
  },
  computed: {
    ...mapState('common', {
      menus: state => state.menu
    }),
    lang() {
      return ISO639ToLan(this.$getLocale())
    }
  },
  watch: {
    $route: {
      handler: () => {},
      immediate: true
    }
  },
  methods: {
    pushRoute(index, path) {
      if (path) {
        this.$router.push({
          path: path
        })
      }
    },
    isHttp(uri) {
      var regex = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/
      return regex.test(uri)
    },
    // showSub(event) {
    //   if (event.cancelable) {
    //     // 判断默认行为是否已经被禁用
    //     if (!event.defaultPrevented) {
    //       event.preventDefault()
    //       this.OpenSub = !this.OpenSub
    //     }
    //   }
    // },
    showSub(id) {
      this.$root.$emit('bv::toggle::collapse', id.toString())
    },
    racingClick(val) {
      if (val.indexOf('major-racing') > 0) {
        let path = val + '/' + this.currentYear
        // let path = val + '/'+ '2018';
        this.$router.push({
          path: path
        })
      }
    }
  },
  filters: {
    url: function(menu, lang) {
      if (menu.url) {
        return menu.url
      } else if (menu.path) {
        return menu.path == 'home' ? `/${lang}/` : `/${lang}/${menu.path}`
      } else {
        return 'javascript:void(0)'
      }
    }
  },
  mounted() {
    // let result = []
    // let result2 = []
    //  result =this.$store.getters.menus.map((e,k)=>
    //   {
    //     for(var i=0;i<e.chilrends.length;i+=4){
    // 	    result2.push({'key':k,'val':e.chilrends.slice(i,i+4)});
    // 	  }
    //     return e.chilrends
    //     }
    //  )
    // console.log(result,result2);
    this.isMounted = true
  }
}
</script>

<style lang="scss" scoped>
.menu {
  line-height: 1.4;
  .nav_pc {
    display: block;

    .nav_item {
      .navbar-collapse {
        height: 70px;
        align-items: flex-end;
        justify-content: flex-end;
      }

      .dropdown-menu li .a_hover {
        color: #343a40;
        padding: 2px 15px;
        // padding-right: 0px;

        &:hover {
          color: #0775dc;
          background-color: #f8f9fa;
        }
      }

      a .dropdown-header:hover {
        color: #0775dc;
      }
    }

    .nav_head_item {
      a {
        padding: 0 0 0 10px;
        font-size: 18px;

        @media screen and (max-width: 1200px) {
          padding: 0 0 0 5px;
          font-size: 17px;
        }
      }
    }

    .nav-font-size {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.5);
      padding-left: 10px;

      @media screen and (max-width: 1200px) {
        padding-left: 5px;
        font-size: 17px;
      }
    }
  }

  .nav_mb {
    display: none;
  }

  @media screen and (max-width: 991px) {
    .nav_mb {
      display: block;

      .toggle_btn {
        font-size: 16px;
        button {
          padding: 0;
          border: none;
          position: relative;
          margin-right: 20px;
        }

        .not-collapsed:after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 8px;
          top: 50px;
          width: 0;
          border-top: 6px solid transparent;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          border-bottom: 6px solid #fed13c;
        }
      }

      .mb_dropdown {
        position: absolute;
        left: 0px;
        width: 100vw;
        top: 169px;
        z-index: 100;
        margin: auto;
        background: #f1f1f1;
        overflow: auto;
        max-height: 80vh;

        .dropdown {
          position: relative;
          margin-top: 0;
          padding-top: 0;
          border-bottom: 1px solid #82b5e4;

          span {
            font-weight: 500;
            color: #000;
          }

          ul {
            // background: #000;
            padding: 0;
            border: none;
            border-radius: 0;
          }

          .dropdown-menu {
            background: #000;
            padding: 0 !important;
            border: none !important;
            border-radius: 0 !important;

            li {
              width: 100%;
            }

            .list-group .list-group-item {
              padding: 0;
              text-align: center;

              p {
                padding: 0.5rem 1rem;
                color: #000;
                opacity: 0.5;
              }

              p:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 17px;
                margin-left: 5px;
                border-top: 6px solid #000;
                opacity: 0.5;
                width: 0;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
                border-bottom: 6px solid transparent;
              }

              .collapse_button {
                background: transparent;
                border: none;
                width: 100%;
                color: inherit;
                padding: 0.5rem 0;
              }

              .collapse_button:active {
                box-shadow: 0 0 black;
              }

              .collapse_button:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 15px;
                margin-left: 10px;
                border-top: 6px solid #000;
                opacity: 0.5;
                width: 0;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
                border-bottom: 6px solid transparent;
              }

              .third_li {
                padding: 0;
                border-left: none;
                border-right: none;
                border-radius: 0;
                background-color: #f1f1f1;
                border-top: 1px solid rgba(0, 0, 0, 0.125);

                a {
                  display: block;
                  width: 100%;
                  color: inherit;
                }
              }

              .nav-item {
                padding: 0;
                background: #fff;
                text-align: center;
              }
            }
          }
        }

        .dropdown:focus-within {
          background: #0d70c9;

          span {
            color: #fff;
          }
        }

        .first_nav_item {
          border-bottom: 1px solid #82b5e4;

          a {
            font-weight: 500;
            color: #000;
          }
        }
      }
    }

    .nav_pc {
      display: none;
    }
  }
}

.navbar {
  position: inherit;

  a {
    text-decoration: none !important;
  }
}

.dropdown-menu {
  background: transparent;

  li {
    padding: 5px 0px 0 30px;
    display: block;
    width: 20vw;
    max-width: 350px;
    header {
      white-space: pre-wrap;
    }

    @media screen and (max-width: 992px) {
      max-width: 100%;
    }
  }
}

.dropdown-header {
  font-size: 1.1rem;
  font-weight: bold;
  color: #000;
  padding: 0;
}

.dropdown-box {
  .small-box {
    padding: 15px 25px;
    margin: 0 auto;
    background: #f0f1f2;
    border-top: 2px solid #fed13c;
    position: relative;
    // top: 1px;
    // @media screen and (max-width: 1200px) {
    //   top: -1px;
    // }
    height: 150px\9;
    height: 150px\0;
    *height: 150px;

    li {
      padding: 0;
      width: 100%;
    }
  }
}

.dropdown-divider {
  border-top: 1px solid #88aedb;
}

.navbar-nav .dropdown-menu {
  margin: 0 auto;
  left: 0;
  width: 100vw;
  top: 136px;
  border: none;
  padding: 13px 0;

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: inherit;
  margin-top: -50px;
  padding-top: 50px;
}

.dropdown:hover .menu-top {
  display: flex;
  max-height: 400px;
  height: 400px\9;
  height: 400px\0;
  *height: 400px;
  // _height: expression(this.scrollHeight > 400 ? '400px': 'auto');

  .menu-content {
    display: flex;
    width: 100vw;
    padding: 25px 15%;
    background: #f0f1f2;
    overflow: auto;

    .content_ul {
      width: 100%;
      align-content: center;
      .last_ul {
        flex-wrap: nowrap;
      }
    }
  }
}

.dropdown:hover {
  .dropdown-toggle.text-dark {
    position: relative;
  }

  .dropdown-toggle.text-dark::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    top: 16px;
    right: 0;
    margin: 10px auto;
    width: 0;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid #fed13d;
  }
}

.lc_title {
  font-size: 20px;
}
</style>
