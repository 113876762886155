import request, { getCommonHeader } from '@/utils/request'

/**
 * 获取表单数据
 * @param {*} params
 */
export function formData({ cookies, slug, preview, id }) {
  return request.get('/api/v1/dynamic_form/data', {
    headers: getCommonHeader({ cookies }),
    params: { slug, preview, id }
  })
}


/**
 * 表单提交
 * @param {*} params 
 * @param {*} data 
 */
export function submit(data = {}) {
  return request.post('/api/v1/dynamic_form/submit', {
    ...data
  })
}