import { FetchAreas, FetchTypes, FetchBanks } from '@/api/bank'

const state = {
  areas: null,
  types: null,
  banks: null
}
const mutations = {
  SET_AREAS: (state, { data }) => {
    state.areas = data
  },
  SET_TYPES: (state, { data }) => {
    state.types = data
  },
  SET_BANKS: (state, { data }) => {
    state.banks = data
  }
}

const actions = {
  FETCH_AREAS: ({ commit }, { cookies }) => {
    return FetchAreas({ cookies }).then(res => {
      commit('SET_AREAS', { data: res.data })
    })
  },
  FETCH_TYPES: ({ commit }, { cookies }) => {
    return FetchTypes({ cookies }).then(res => {
      commit('SET_TYPES', { data: res.data })
    })
  },
  FETCH_BANKS: ({ commit }, { cookies, preview, id, vid }) => {
    return FetchBanks({ cookies, preview, id, vid }).then(res => {
      commit('SET_BANKS', { data: res.data })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
